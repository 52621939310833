import * as React from "react"
import { graphql } from "gatsby"
// import { graphql} from "gatsby"
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import SEO from "@components/atoms/Seo"
import PageContent from "@projects/PageContent"


function IndexPage()
{
    return (
        <>
            <SEO subtitle="プライバシーポリシー／個人情報保護方針"/>
            <PageContent title="プライバシーポリシー／個人情報保護方針">
                <p>
                    株式会社WONDER&CLOCKS（以下、当社という）は、個人情報の保護に関する法令等を遵守するとともに、ここに規定するプライバシーポリシーを遵守いたします。
                </p>
                <section>
                    <h4>個人情報の管理</h4>
                    <p>
                        当社は、お客様の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
                    </p>
                </section>
                <section>
                    <h4>個人情報の利用目的</h4>
                    <p>
                        本ウェブサイトでは、お客様からのお問い合わせ時に、お名前、e-mailアドレス、電話番号等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。
                        お預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
                    </p>
                </section>
                <section>
                    <h4>個人情報の第三者への開示・提供の禁止</h4>
                    <p>
                        当社は、お客様よりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。<br/>
                        ・お客様の同意がある場合<br />
                        ・お客様が希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合<br />
                        ・法令に基づき開示することが必要である場合<br />
                    </p>
                </section>
                <section>
                    <h4>個人情報の安全対策</h4>
                    <p>
                        当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
                    </p>
                </section>
                <section>
                    <h4>ご本人の照会</h4>
                    <p>
                        お客様がご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
                        （入学後にSNSやYouTubeで公開済みの写真・動画に関しては当社に帰属する為対応出来かねます。）
                    </p>
                </section>
                <section>
                    <h4>法令、規範の遵守と見直し</h4>
                    <p>
                        当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
                    </p>
                </section>
                <section>
                    <h4>お問い合せ</h4>
                    <p>
                        当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。<br/>
                        WONDER & CLOCKS中目黒支社<br />
                        〒 153-0051<br />
                        東京都目黒区上目黒2-9-35<br />
                        中目黒第2GSビルB1<br />
                        info@wonder-clocks.com
                    </p>
                </section>
            </PageContent>
        </>
    )
}

export const pagerQuery = graphql`
    fragment Thumbnail on ImageSharp {
        gatsbyImageData(
            width: 1280
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 80
        )
    }
`
export default IndexPage
